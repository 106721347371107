import { useTranslation } from 'react-i18next';

const Story = () => {
  const { t } = useTranslation();

  return (
    <section className="">
      <h2 className="c-text--h2-ttl ">{t('story.001')}</h2>

      <p className="c-text--md u-mt--20">
        {t('story.002')}
        <br />
        {t('story.003')}
        <br />
        <br />
        {t('story.004')}
        <br />
        {t('story.005')}
        <br />
        <br />
        {t('story.006')}
        <br />
        <br />
        {t('story.007')}
        <br />
        <br />
        {t('story.008')}
      </p>
    </section>
  );
};

export default Story;
