import React, { useState, useEffect } from 'react';
import NFTTitle from './NFTTitle';
import ActionButton from './ActionButton';

const NFTCard = ({
  initialInfo,
  isConnected,
  monsters,
  address,
  signature,
  onLootexClick,
  onElementClick,
  onMintleClick,
  onExplorerClick,
  isLoading,
  setMonsters,
  setRenamedMonsters,
  fetchNonceAndLogin,
}) => {
  const [info, setInfo] = useState(initialInfo);

  useEffect(() => {
    setInfo(initialInfo);
  }, [initialInfo]);

  const spinnerContent = isLoading ? (
    <>
      <div className="overlay"></div>
      <div className="loading-spinner"></div>
    </>
  ) : null;

  return (
    <div className="p-nft u-mt--20">
      <p className={`p-nft__img ${isLoading ? 'isLoading' : ''}`}>
        {spinnerContent}
        <img
          src={info?.image}
          alt={`Last Generation NFT #${info?.tokenId || 'Unknown'}`}
        />
      </p>
      <div className="c-layer">
        {(() => {
          if (info?.isUsed) {
            return (
              <>
                <NFTTitle
                  isConnected={isConnected}
                  tokenId={info?.tokenId}
                  name={info?.name}
                />
              </>
            );
          } else if (info?.isLocked) {
            return <></>;
          } else if (!info?.isLocked) {
            return (
              <>
                <NFTTitle
                  isConnected={isConnected}
                  tokenId={info?.tokenId}
                  name={info?.name}
                />
              </>
            );
          } else if (!info?.isUsed) {
            return <></>;
          }
        })()}

        <ActionButton
          isConnected={isConnected}
          info={info}
          setInfo={setInfo}
          monsters={monsters}
          onLootexClick={onLootexClick}
          onElementClick={onElementClick}
          onMintleClick={onMintleClick}
          onExplorerClick={onExplorerClick}
          address={address}
          signature={signature}
          setMonsters={setMonsters}
          setRenamedMonsters={setRenamedMonsters}
          fetchNonceAndLogin={fetchNonceAndLogin}
        />
      </div>
    </div>
  );
};

export default NFTCard;
