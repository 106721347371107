import axios from 'axios';
import { api_endpoint } from '../constant';
import { devConsoleApi } from '../common';

export const vanguardAttackApi = async (
  eventId: number,
  tokenId: number,
  address: string,
  signature: string
): Promise<{ success: boolean }> => {
  devConsoleApi(`vanguardAttackApi()`, eventId, tokenId, address, signature);

  try {
    const response = await axios.post(
      `${api_endpoint}attack/preferential`,
      {
        eventId: eventId,
        tokenId: tokenId,
        walletAddress: address,
        signature: signature,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    devConsoleApi(`vanguardAttackApi() response`, response?.data);

    return response?.data;
  } catch (error) {
    console.error('vanguardAttackApi error:', error);
    throw error;
  }
};

export const todaysAttackApi = async (
  eventId: number,
  tokenId: number,
  address: string,
  signature: string
): Promise<{ success: boolean }> => {
  devConsoleApi(`todaysAttackApi()`, eventId, tokenId, address, signature);

  try {
    const response = await axios.post(
      `${api_endpoint}attack/normal`,
      {
        eventId: eventId,
        tokenId: tokenId,
        walletAddress: address,
        signature: signature,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    devConsoleApi(`todaysAttackApi() response`, response?.data);

    return response?.data;
  } catch (error) {
    console.error('todaysAttackApi error:', error);
    throw error;
  }
};

export const lockApi = async (
  tokenId: number,
  address: string,
  signature: string
): Promise<{ success: boolean }> => {
  devConsoleApi(`lockApi()`, tokenId, address, signature);

  try {
    const response = await axios.post(
      `${api_endpoint}nft/lock`,
      {
        tokenId: tokenId,
        walletAddress: address,
        signature: signature,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    devConsoleApi(`lockApi() response`, response?.data);

    return response?.data;
  } catch (error) {
    console.error('lockApi error:', error);
    throw error;
  }
};

export const attackApi = async (
  eventId: number,
  tokenId: number,
  vanguard: boolean,
  address: string,
  signature: string
): Promise<{ success: boolean }> => {
  devConsoleApi(`attackApi()`, eventId, tokenId, vanguard, address, signature);

  try {
    let response;
    if (vanguard) {
      response = await vanguardAttackApi(eventId, tokenId, address, signature);
    } else {
      response = await todaysAttackApi(eventId, tokenId, address, signature);
    }

    devConsoleApi(`attackApi() response`, response);

    return response;
  } catch (error) {
    console.error('attackApi error:', error);
    throw error;
  }
};
