import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { lockApi, attackApi } from './../../lib/api/attack';
import { monsterSchedule, renameMonster } from './../../lib/services';
import { devConsoleInfo } from 'lib/common';
import { fetchNftApi } from 'lib/api/nft';
import { toast } from 'react-toastify';
import { mantle_instance_explorer_url } from 'lib/constant';

const ActionButton = ({
  isConnected,
  info,
  setInfo,
  monsters,
  address,
  signature,
  onElementClick,
  onMintleClick,
  onLootexClick,
  onExplorerClick,
  setMonsters,
  setRenamedMonsters,
  fetchNonceAndLogin,
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [selectedMonsterId, setSelectedMonsterId] = useState(monsters[0]?.eid);
  const [isLoading, setIsLoading] = useState(false);
  const [attackMonster, setAttackMonster] = useState('');

  useEffect(() => {
    if (monsters.length > 0) {
      setSelectedMonsterId(monsters[0]?.eid);
    }
  }, [monsters]);

  useEffect(() => {
    const fetchNft = async () => {
      try {
        const nft = await fetchNftApi(info?.tokenId, address, signature);
        setAttackMonster(nft?.UserEvent[0]?.event?.name);
      } catch (error) {
        console.error('Failed to fetch Event:', error);
      }
    };

    if (info?.isUsed && info?.tokenId && address && signature) {
      fetchNft();
    }
  }, [info, signature]);

  const handleSelectChange = (event) => {
    devConsoleInfo([`handleSelectChange()`, event?.target?.value]);
    setSelectedMonsterId(Number(event.target.value));
  };

  const onBattleClick = async (eventId, tokenId) => {
    let vanguard;
    monsters.find((monster) => {
      if (monster?.eid === eventId) {
        vanguard = monster.vanguard;
      }
    });

    devConsoleInfo(`onBattleClick()`, eventId, tokenId, vanguard);

    if (
      eventId &&
      tokenId &&
      vanguard != undefined &&
      vanguard != null &&
      address &&
      signature
    ) {
      if (!address || !signature) {
        await fetchNonceAndLogin();
        return;
      }

      setIsLoading(true);
      try {
        const res = await attackApi(
          eventId,
          tokenId,
          vanguard,
          address,
          signature
        );
        if (res.success) {
          toast.success(t(`success.attack`));

          setInfo({
            ...info,
            isUsed: true,
          });

          const monstersData = await monsterSchedule();
          setMonsters(monstersData);
          const renamedMonsters = renameMonster(monstersData, t);
          setRenamedMonsters(renamedMonsters);
        } else {
        }
      } catch (error) {
        console.error('Failed to attackApi:', error);
        toast.error(t(`error.attack`));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onLockClick = async () => {
    if (!address || !signature) {
      await fetchNonceAndLogin();
      return;
    }

    setIsLoading(true);
    try {
      const res = await lockApi(info.tokenId, address, signature);
      if (res.success) {
        toast.success(t(`success.lock`));

        setInfo({
          ...info,
          isLocked: true,
        });
      } else {
      }
    } catch (error) {
      console.error('Failed to locking NFT:', error);
      toast.error(t(`error.lock`));
    } finally {
      setIsLoading(false);
    }
  };

  if (!isConnected) {
    return (
      <div className="p-nft__btn">
        <ConnectButton className="p-fv__btn-link" />
      </div>
    );
  }
  if (!info?.tokenId) {
    return (
      <>
        <button
          type="button"
          className="c-btn c-btn--gradation-noToken"
          onClick={onLootexClick}
        >
          {t('monsters.lootex')}
        </button>
        <button
          type="button"
          className="c-btn c-btn--gradation-noToken"
          onClick={onElementClick}
        >
          {t('monsters.element')}
        </button>
        <button
          type="button"
          className="c-btn c-btn--gradation-noToken"
          onClick={onMintleClick}
        >
          {t('monsters.mintle')}
        </button>
      </>
    );
  }

  return (
    <div>
      {monsters.length > 0 && (
        <>
          {(() => {
            if (info?.isUsed) {
              return (
                <>
                  <div className="p-nft__btn">
                    <button
                      type="button"
                      className="c-btn c-btn--gradation"
                      disabled={true}
                    >
                      {t('sidebar.committed')}{' '}
                      {currentLanguage === 'en' && attackMonster ? 'the' : ''}{' '}
                      {`${attackMonster}`}
                    </button>
                  </div>
                  <div className="p-nft__btn">
                    {info?.tokenId && (
                      <a
                        href={`${mantle_instance_explorer_url}${info.tokenId}`}
                        className="c-btn--explorer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('monsters.explorer')}
                      </a>
                    )}
                  </div>
                </>
              );
            } else if (info?.isLocked) {
              return (
                <>
                  <div className="c-btn-wrap--select">
                    <select
                      name="monsterSelect"
                      id="monsterSelect"
                      className="c-btn--select"
                      aria-required="true"
                      required
                      onChange={handleSelectChange}
                      value={selectedMonsterId}
                    >
                      {monsters.map((monster) => (
                        <option key={monster?.eid} value={monster?.eid}>
                          {monster.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="p-nft__btn">
                    <button
                      type="button"
                      className={`c-btn c-btn--gradation ${isLoading ? 'c-btn--disabled' : ''}`}
                      onClick={() =>
                        onBattleClick(selectedMonsterId, info?.tokenId)
                      }
                    >
                      {isLoading ? (
                        <div className="loading-overlay">
                          <div className="loading-spinner"></div>
                        </div>
                      ) : (
                        t('sidebar.battle')
                      )}
                    </button>
                  </div>
                  <div className="p-nft__btn">
                    {info?.tokenId && (
                      <a
                        href={`${mantle_instance_explorer_url}${info.tokenId}`}
                        className="c-btn--explorer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('monsters.explorer')}
                      </a>
                    )}
                  </div>
                </>
              );
            } else if (!info?.isLocked) {
              return (
                <>
                  <div className="p-nft__btn" style={{ position: 'relative' }}>
                    <button
                      type="button"
                      className={`c-btn c-btn--gradation ${isLoading ? 'c-btn--disabled' : ''}`}
                      onClick={() => onLockClick()}
                    >
                      {isLoading ? (
                        <div className="loading-overlay">
                          <div className="loading-spinner"></div>
                        </div>
                      ) : (
                        t('sidebar.lock')
                      )}
                    </button>
                  </div>
                  <div className="p-nft__btn">
                    {info?.tokenId && (
                      <a
                        href={`${mantle_instance_explorer_url}${info.tokenId}`}
                        className="c-btn--explorer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('monsters.explorer')}
                      </a>
                    )}
                  </div>
                </>
              );
            } else if (!info?.isUsed) {
              return (
                <>
                  <div className="p-nft__btn">
                    <button
                      type="button"
                      className="c-btn c-btn--gradation"
                      disabled={true}
                    >
                      {t('sidebar.committed')}
                    </button>
                  </div>
                  <div className="p-nft__btn">
                    {info?.tokenId && (
                      <a
                        href={`${mantle_instance_explorer_url}${info.tokenId}`}
                        className="c-btn--explorer"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('monsters.explorer')}
                      </a>
                    )}
                  </div>
                </>
              );
            }
          })()}
        </>
      )}
      {monsters.length === 0 && (
        <>
          <div className="p-nft__btn">
            <button
              type="button"
              className="c-btn c-btn--gradation"
              disabled={true}
            >
              {t('sidebar.end')}
            </button>
          </div>
          <button
            type="button"
            className="c-btn--explorer"
            onClick={onExplorerClick(info?.tokenId)}
          >
            {t('monsters.explorer')}
          </button>
        </>
      )}
    </div>
  );
};

export default ActionButton;
