import { useTranslation } from 'react-i18next';

const Monsters = () => {
  const { t } = useTranslation();

  return (
    <section id="monsters" className="u-mt--160">
      <h2 className="c-text--h2-ttl">{t('monsters.001')}</h2>

      <p className="u-mt--30">
        <img src="/images/img_monster_01.jpg" alt="img_monster_01" />
      </p>

      <p className="c-text--sub-ttl-md u-mt--40">
        “{t('monster.001')}” / {t('monsters.002')}
      </p>

      <p className="c-text--md u-mt--20">
        {t('monsters.003')}: 1,000{' / '}
        {t('monsters.004')}: 220,000 pts{' / '}
        {t('monsters.005')}: 330,000 pts
        <br />
        <br />
        {t('monsters.006')}
        <br />
        {t('monsters.007')}
        <br />
        {t('monsters.008')}
      </p>

      <p className="u-mt--80">
        <img src="/images/img_monster_02.jpg" alt="img_monster_02" />
      </p>

      <p className="c-text--sub-ttl-md u-mt--40">
        “{t('monster.002')}” / {t('monsters.009')}
      </p>

      <p className="c-text--md u-mt--20">
        {t('monsters.003')}: 300{' / '}
        {t('monsters.004')}: 70,000 pts{' / '}
        {t('monsters.005')}: 120,000 pts
        <br />
        <br />
        {t('monsters.010')}
      </p>

      <p className="u-mt--80">
        <img src="/images/img_monster_03.jpg" alt="img_monster_03" />
      </p>

      <p className="c-text--sub-ttl-md u-mt--40">
        “{t('monster.003')}” / {t('monsters.011')}
      </p>

      <p className="c-text--md u-mt--20">
        {t('monsters.003')}: 500{' / '}
        {t('monsters.004')}: 140,000 pts{' / '}
        {t('monsters.005')}: 120,000 pts
        <br />
        <br />
        {t('monsters.012')}
        <br />
        {t('monsters.013')}
        <br />
        {t('monsters.014')}
      </p>

      <p className="u-mt--80">
        <img src="/images/img_monster_04.jpg" alt="img_monster_04" />
      </p>

      <p className="c-text--sub-ttl-md u-mt--40">
        “{t('monster.004')}” / {t('monsters.015')}
      </p>

      <p className="c-text--md u-mt--20">
        {t('monsters.003')}: ??{' / '}
        {t('monsters.004')}: 220,000 pts{' / '}
        {t('monsters.005')}: 330,000 pts
        <br />
        <br />
        {t('monsters.016')}
        <br />
        {t('monsters.017')}
        <br />
        {t('monsters.018')}
      </p>
    </section>
  );
};

export default Monsters;
