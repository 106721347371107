import { env } from './constant';

export function devConsoleInfo(...args: any[]): void {
  if (env !== 'production') {
    const formattedArgs = args.map((arg) =>
      typeof arg === 'object' ? JSON.stringify(arg, null, 2) : arg
    );
    console.info(...formattedArgs);
  }
}

export function devConsoleApi(...args: any[]): void {
  if (env !== 'production') {
    const formattedArgs = args.map((arg) =>
      typeof arg === 'object' ? JSON.stringify(arg, null, 2) : arg
    );
    console.info('\x1b[32m%s\x1b[0m', [...formattedArgs].join(' '));
  }
}

export function consoleOnchain(...args: any[]) {
  if (env !== 'production') {
    const formattedArgs = args.map((arg) =>
      typeof arg === 'object' ? JSON.stringify(arg, null, 2) : arg
    );
    console.info('\x1b[31m%s\x1b[0m', [...formattedArgs].join(' '));
  }
}
