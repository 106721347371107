import { useTranslation } from 'react-i18next';

const Strategy = () => {
  const { t } = useTranslation();

  return (
    <section id="strategy" className="u-mt--150">
      <h2 className="c-text--h2-ttl">{t('strategy.001')}</h2>

      <p className="u-mt--90">
        <img src="/images/img_strategy_01.jpg" alt="img_strategy_01" />
      </p>

      <p className="c-text--md u-mt--20">
        {t('strategy.002')}
        <br />
        {t('strategy.003')}
        <br />
        {t('strategy.004')}
        <br />
        <br />
        {t('strategy.005')}
        <br />
        {t('strategy.006')}
      </p>

      <p className="u-mt--40">
        <img src="/images/img_explain_01.jpg" alt="img_explain_01" />
      </p>
      <p className="u-mt--90 u-ml--12"></p>
      <p className="u-mt--80">
        <img src="/images/img_strategy_02.jpg" alt="img_strategy_02" />
      </p>

      <p className="c-text--md u-mt--10">{t('strategy.007')}</p>

      <p className="u-mt--40">
        <img src="/images/img_explain_02.jpg" alt="img_explain_02" />
      </p>
      <p className="u-mt--90 u-ml--12"></p>
      {/* <p className="u-mt--70">
        <img src="/images/img_strategy_03.jpg" alt="" />
      </p>

      <p className="c-text--md u-mt--10">
        {t('strategy.008')}
        <br />
        {t('strategy.009')}
      </p> */}

      {/* <p className="u-mt--70">
        <img src="/images/img_explain_03.jpg" alt="" />
      </p> */}
      {/* <p className="u-mt--70 u-ml--12">
      </p> */}
      <p className="u-mt--30">
        <img src="/images/img_strategy_04.jpg" alt="img_strategy_04" />
      </p>

      <p className="c-text--md u-mt--10">
        {t('strategy.010')}
        <br />
        {t('strategy.011')}
        <br />
        {t('strategy.012')}
        <br />
        {t('strategy.013')}
      </p>

      {/* <p className="c-text--sub-ttl-sm u-mt--30">{t('strategy.014')}</p> */}

      <p className="u-mt--40 u-ml---12">
        <img src="/images/img_explain_05.jpg" alt="img_explain_05" />
      </p>

      <p className="u-mt--10 u-ml---12">
        <img src="/images/img_explain_04.png" alt="img_explain_04" />
      </p>

      <p className="c-text--sub-ttl-sm">
        {t('strategy.015')} = 330ETH
        <br />
        {t('strategy.016')} = 450ETH
        <br />
        {t('strategy.017')} = 450ETH - 330ETH = 120ETH
      </p>

      <p className="u-mt--40 u-ml--12"></p>
      {/* <p className="u-mt--70">
        <img src="/images/img_strategy_05.jpg" alt="" />
      </p> */}
      {/*
      <p className="c-text--md u-mt--10">
        {t('strategy.018')}
        {t('strategy.019')}
        {t('strategy.020')}
      </p>
 */}
    </section>
  );
};

export default Strategy;
