import { useTranslation, Trans } from 'react-i18next';
import { element_url, mintle_url, lootex_url } from './../../../lib/constant';

const Rule = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const fontClass = currentLanguage === 'ja' ? 'font-ja' : 'font-en';
  const imagePath =
    currentLanguage === 'ja'
      ? '/images/img_rule.svg'
      : '/images/img_rule_en.svg';
  const fontSize = currentLanguage === 'ja' ? 'font-size-20' : '';

  return (
    <section id="rules" className="u-mt--90">
      <h2 className="c-text--h2-ttl ">{t('rule.001')}</h2>

      <p className="u-mt--30">
        <img src={imagePath} alt={`${imagePath}`} />
      </p>

      <p className={`c-text--sub-ttl-md u-mt--50 ${fontClass}`}>
        {t('rule.002')}
      </p>

      <div className="p-card p-card--rules u-mt--30">
        <p>
          <img src="/images/img_secret_box.jpg" alt="img_secret_box" />
        </p>

        <div>
          <p className={`${fontClass} ${fontSize}`}>
            <Trans i18nKey={'rule.003'} />
            <br />
            {/* {t('rule.004')} = 1 ETH */}
          </p>
        </div>
      </div>

      <p className="c-text--md u-mt--30">
        {t('rule.005')}
        <br />
        {t('rule.006')}
      </p>

      <ul className="c-list--nft u-mt--40">
        <li>
          <a href={lootex_url} target="_blank" rel="noopener noreferrer">
            <img src="/images/img_bnr_root.png" alt="" />
          </a>
        </li>
        <li>
          <a href={mintle_url} target="_blank" rel="noopener noreferrer">
            <img src="/images/img_bnr_mintle.png" alt="img_bnr_mintle" />
          </a>
        </li>
        <li>
          <a href={element_url} target="_blank" rel="noopener noreferrer">
            <img src="/images/Banner_Element.png" alt="Banner_Element" />
          </a>
        </li>
      </ul>

      {/* <p className="c-text--xl u-mt--80">{t('rule.007')}</p> */}
      {/* <p className="c-text--md">
        {t('rule.008')}
        <br />
        {t('rule.009')}
      </p> */}

      <p className={`c-text--sub-ttl-md u-mt--80 ${fontClass}`}>
        {t('rule.010')}
      </p>
      <p className="c-text--md u-mt--20">{t('rule.011')}</p>

      <p className={`c-text--sub-ttl-md u-mt--80 ${fontClass}`}>
        {t('rule.012')}
      </p>
      <p className="c-text--md u-mt--20">
        {t('rule.013')}
        <br />
        {t('rule.014')}
        <br />
        <br />
        {t('rule.015')}
        <br />

        {t('rule.016')}
        <br />
        {t('rule.017')}
      </p>

      <p className={`c-text--sub-ttl-md u-mt--60 ${fontClass}`}>
        {t('rule.018')}
      </p>
      <p className="c-text--md u-mt--20">{t('rule.019')}</p>

      <p className={`c-text--sub-ttl-md u-mt--60 ${fontClass}`}>
        {t('rule.020')}
      </p>
      <p className={`c-text--md u-mt--20`}>
        {t('rule.021')}
        {/* <br /> */}
        {/* <br />＜{t('rule.022')}＞<br /> */}
        {/* {t('rule.023')} */}
        {/* <br />
        <br />＜{t('rule.024')}＞<br />
        {t('rule.025')}
        <br />
        <br />＜{t('rule.026')}＞<br /> */}
        {/* {t('rule.027')} */}
      </p>
    </section>
  );
};

export default Rule;
