import { MonsterApiResponse, monsterDataApi } from './api/monster';
import { devConsoleInfo } from './common';

interface Monster {
  id: number;
  eid: number;
  name: string;
  startTime: Date;
  endTime: Date;
  main: string;
  thumb: string;
  result: string;
  maxHp: number;
  vanguard: boolean | null;
  vanguardCount: number;
  totalAttackCount: number;
}

export async function monsterSchedule(): Promise<Monster[]> {
  devConsoleInfo(`monsterSchedule()`);

  const response: MonsterApiResponse[] = await monsterDataApi();
  const now = new Date();

  const updatedMonsters = response.map((monster) => {
    const startTime = new Date(monster.startTime);
    const endTime = new Date(monster.endTime);
    let newVanguard = monster.vanguard;

    if (now >= startTime && now < endTime && monster.name === 'DRAGON') {
      // DRAGON の当日攻撃
      newVanguard = false;
    } else if (
      // DRAGON の当日攻撃終わり
      now >= endTime &&
      monster.name === 'DRAGON'
    ) {
      newVanguard = null;
    } else if (
      now >= startTime &&
      now < endTime &&
      monster.name === 'SUCCUBUS'
    ) {
      // SUCCUBUS の当日攻撃
      newVanguard = false;
    } else if (now >= endTime && monster.name === 'SUCCUBUS') {
      // SUCCUBUS の当日攻撃終わり
      newVanguard = null;
    } else if (now >= startTime && now < endTime && monster.name === 'DEVIL') {
      // DEVIL の当日攻撃
      newVanguard = false;
    } else if (now >= endTime && monster.name === 'DEVIL') {
      // DEVIL の当日攻撃終わり
      newVanguard = null;
    } else if (
      now >= startTime &&
      now < endTime &&
      monster.name === 'LAST DRAGON'
    ) {
      // LAST DRAGON の当日攻撃
      if (monster.vanguard === true) {
        newVanguard = false;
      }
    } else if (now >= endTime && monster.name === 'LAST DRAGON') {
      // LAST DRAGON の当日攻撃終わり
      newVanguard = null;
    }

    const monsters = {
      ...monster,
      vanguard: newVanguard,
    };
    devConsoleInfo(`monsterSchedule() response: `, monsters);

    return monsters;
  });

  return updatedMonsters;
}

export function renameMonster(
  inputMonsters: Monster[] | any,
  t: any
): Monster[] {
  devConsoleInfo(`renameMonster()`, inputMonsters);

  if (!Array.isArray(inputMonsters)) {
    console.error('Invalid input: inputMonsters is not an array.');
    return [];
  }

  const res = inputMonsters
    .filter((monster) => monster.vanguard !== null)
    .map((monster) => {
      if (monster.vanguard === true) {
        monster.name += ` (${t('sidebar.vanguard')})`;
      } else {
        monster.name += ` (${t('sidebar.attack')})`;
      }
      return monster;
    });

  devConsoleInfo(`renameMonster() response: `, res);

  return res;
}

export function selectLatestMonster(monsters: Monster[]) {
  devConsoleInfo(`selectLatestMonster()`, monsters);

  const lastDragon = monsters.find((monster) => monster.name === 'LAST DRAGON');
  const devil = monsters.find((monster) => monster.name === 'DEVIL');

  let res;
  if (lastDragon && lastDragon.vanguard === false) {
    res = lastDragon;
  } else if (lastDragon?.vanguard === null && devil?.vanguard === null) {
    res = lastDragon;
  } else if (devil) {
    res = devil;
  } else {
    res = [];
  }

  devConsoleInfo(`selectLatestMonster() response: `, res);

  return res;
}
