import {
  element_url,
  mintle_url,
  lootex_url,
  mantle_instance_explorer_url,
} from './../../lib/constant';
import NFTCard from './NFTCard';

const NFTList = ({
  nftInfos,
  isConnected,
  monsters,
  address,
  signature,
  isLoading,
  setMonsters,
  setRenamedMonsters,
  fetchNonceAndLogin,
}) => {
  const handleLootexClick = () => window.open(lootex_url, '_blank');
  const handleElementClick = () => window.open(element_url, '_blank');
  const handleMintleClick = () => window.open(mintle_url, '_blank');
  const handleExplorerClick = (tokenId) =>
    window.open(`${mantle_instance_explorer_url}${tokenId}`, '_blank');

  return nftInfos.map((info, index) => (
    <NFTCard
      key={index}
      initialInfo={info}
      monsters={monsters}
      isConnected={isConnected}
      address={address}
      signature={signature}
      onLootexClick={handleLootexClick}
      onElementClick={handleElementClick}
      onMintleClick={handleMintleClick}
      onExplorerClick={handleExplorerClick}
      isLoading={isLoading}
      setMonsters={setMonsters}
      setRenamedMonsters={setRenamedMonsters}
      fetchNonceAndLogin={fetchNonceAndLogin}
    />
  ));
};

export default NFTList;
