import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

const Timer = ({ monsters, renamedMonsters }) => {
  const { t } = useTranslation();
  const now = new Date();
  const nextMonster = monsters.find(
    (monster) => new Date(monster.startTime) > now
  );

  const [monster, setMonster] = useState(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let current;
    if (!current) {
      current =
        renamedMonsters && renamedMonsters.length > 0 ? nextMonster : null;
      setMonster(current);

      setIsLoading(false);
    }

    if (current) {
      const startTime = new Date(current.startTime);
      const diff = Math.floor((startTime - now) / 1000);
      setTimeLeft(diff > 0 ? diff : 0);

      const intervalId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          const newTimeLeft = prevTimeLeft - 1;
          if (newTimeLeft < 1) {
            clearInterval(intervalId);
            return 0;
          }
          return newTimeLeft;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [monsters, renamedMonsters]);

  if (isLoading) {
    return (
      <>
        <div className="p-card p-card--attack u-mb--30 timer">
          <p>
            <div className="loading_card">
              <div className="loading-spinner"></div>
            </div>
          </p>
          <div>
            <p class="loading_title"></p>
          </div>
        </div>
      </>
    );
  }

  if (!monster?.main) {
    return (
      <>
        <div className="p-card p-card--attack u-mb--30 timer">
          <p>
            {!monsters[monsters.length - 1]?.thumb ? (
              <div className="loading_card">
                <div className="loading-spinner"></div>
              </div>
            ) : (
              <img
                width={170}
                height={170}
                src={monsters[monsters.length - 1]?.thumb}
                alt={`${monsters[monsters.length - 1]?.thumb}`}
              />
            )}
          </p>
          {!monsters[monsters.length - 1]?.thumb ? (
            <div>
              <p class="loading_title"></p>
            </div>
          ) : (
            <div>
              <p>{t(`battle.end`)}</p>
              <span>{`00:00:00`}</span>
            </div>
          )}
        </div>
      </>
    );
  }

  const formatTime = () => {
    const hours = Math.floor(timeLeft / 3600);
    const minutes = Math.floor((timeLeft % 3600) / 60);
    const seconds = timeLeft % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="p-card p-card--attack u-mb--30">
      <p>
        <img
          width={170}
          height={170}
          src={monster?.thumb}
          alt={`${monster?.thumb}`}
        />
      </p>
      <div className="p-card--info" height="170">
        <p>
          {t('timer.001')} {t(`monster.00${monster?.eid}`)}
        </p>
        <span>
          <span>{formatTime()}</span>
        </span>
      </div>
    </div>
  );
};

export default Timer;
