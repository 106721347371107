import { providers } from 'ethers';
import axios from 'axios';
import { api_endpoint } from '../constant';
import { devConsoleApi } from '../common';

export const nonceApi = async (address: string): Promise<{ nonce: string }> => {
  devConsoleApi(`nonceApi()`, address);

  try {
    const response = await axios.post(
      `${api_endpoint}auth/nonce`,
      {
        walletAddress: address,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    devConsoleApi(`nonceApi() response`, response?.data);
    return {
      nonce: response.data.nonce,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('nonceApi error:', error.message);
    } else {
      console.error('nonceApi error:', error);
    }
    throw new Error('Failed to fetch nonce from server');
  }
};

export const loginApi = async (
  address: string,
  signature: string
): Promise<any> => {
  devConsoleApi(`loginApi()`, address, signature);

  try {
    const response = await axios.post(
      `${api_endpoint}auth/login`,
      {
        walletAddress: address,
        signature: signature,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    devConsoleApi(`loginApi() response`, response?.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('loginApi error:', error.message);
    } else {
      console.error('loginApi error:', error);
    }
    throw new Error('loginApi error: Failed to fetch nonce from server');
  }
};

export const auth = async (
  address: string,
  signer: providers.JsonRpcSigner
): Promise<any> => {
  devConsoleApi(`auth()`, address, signer);

  try {
    const { nonce } = await nonceApi(address);
    if (!nonce) {
      throw new Error('auth error: not get nonce to api');
    } else {
      const signature = await signer.signMessage(nonce);
      const response = await loginApi(address, signature);

      if (response.success) {
        devConsoleApi(`auth() response`, signature, response?.data);
        return signature;
      } else {
        const signature = await signer.signMessage(response.nonce);
        const response2 = await loginApi(address, signature);
        devConsoleApi(`auth() response2`, signature, response2?.data);
        return signature;
      }
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('auth error:', error.message);
    } else {
      console.error('auth error:', error);
    }
    throw new Error('auth error: Failed to fetch nonce from server');
  }
};
