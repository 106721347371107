import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FirstView from 'components/pages/top/FirstView';
import Sidebar from 'components/pages/top/Sidebar';
import Timer from 'components/pages/top/Timer';
import Battle from 'components/pages/top/Battle';
import Summary from 'components/pages/top/Summary';
import Story from 'components/pages/top/Story';
import Monsters from 'components/pages/top/Monsters';
import Reward from 'components/pages/top/Reward';
import Heros from 'components/pages/top/Heros';
import Rule from 'components/pages/top/Rule';
import Strategy from 'components/pages/top/Strategy';
import Schedule from 'components/pages/top/Schedule';
import Faq from 'components/pages/top/Faq';
import { useAccount } from 'wagmi';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useEthersSigner } from './../lib/provider';
import { auth } from '../lib/api/auth';
import { monsterSchedule, renameMonster } from '../lib/services';
import { toast } from 'react-toastify';

const Top = () => {
  const { t } = useTranslation();
  const [monsters, setMonsters] = useState([]);
  const [renamedMonsters, setRenamedMonsters] = useState([]);
  const { isConnected, address } = useAccount();
  const { openConnectModal } = useConnectModal();
  const signer = useEthersSigner();
  const [signature, setSignature] = useState(null);
  const [isLogin, setIsLogin] = useState(false);

  const fetchMonsters = async () => {
    try {
      const monstersData = await monsterSchedule();
      setMonsters(monstersData);
      const renamedMonsters = renameMonster(monstersData, t);
      setRenamedMonsters(renamedMonsters);
    } catch (error) {
      console.error('Failed to fetch or rename monsters:', error);
      toast.error(t('error.fetchMonsters'));
    }
  };

  const fetchNonceAndLogin = async () => {
    try {
      if (!isConnected) {
        openConnectModal();
      }
      if (signer && !signature && !isLogin) {
        const response = await auth(address, signer);
        setIsLogin(signature ? true : false);
        setSignature(response);
        if (response) {
          toast.success(t('success.login'));
        }
      }
    } catch (error) {
      console.error('Error in fetchNonceAndLogin:', error);
      toast.error(t('error.login'));
    }
  };

  useEffect(() => {
    fetchMonsters();
  }, [t]);

  useEffect(() => {
    fetchNonceAndLogin();
  }, [signer, isConnected, openConnectModal, address, isLogin, signature]);

  return (
    <main>
      <FirstView />

      <div className="l-content">
        <div className="l-content__inner">
          <Sidebar
            isConnected={isConnected}
            monsters={renamedMonsters}
            address={address}
            signature={signature}
            setMonsters={setMonsters}
            setRenamedMonsters={setRenamedMonsters}
            fetchNonceAndLogin={fetchNonceAndLogin}
          />
          <div className="l-content__main">
            <Timer monsters={monsters} renamedMonsters={renamedMonsters} />
            <Battle
              monsters={monsters}
              setMonsters={setMonsters}
              renamedMonsters={renamedMonsters}
            />
            <a
              href="/images/TimeSchedule.jpg"
              className="u-mt--24"
              target="_blank"
            >
              <img src="/images/TimeSchedule.jpg" width="100%" />
            </a>
            <Summary />
            <Story />
            <Monsters />
            <Reward />
            <Heros />
            <Rule />
            <Strategy />
            <Schedule />
            <Faq />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Top;
