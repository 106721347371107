import React from 'react';
import { useTranslation } from 'react-i18next';

const Summary = () => {
  const { t } = useTranslation();
  return (
    <section className="">
      <h2 className="c-text--h2-ttl u-mt--80">{t('summary.001')}</h2>

      <p className="c-text--md u-mt--30">
        {t('summary.002')}
        <br />
        {t('summary.003')}
        <br />
        <br />
        {t('summary.004')}
      </p>
    </section>
  );
};

export default Summary;
