import { useTranslation, Trans } from 'react-i18next';

const Reword = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const imagePath =
    currentLanguage === 'ja'
      ? '/images/img_reword_table_jp.jpg'
      : '/images/img_reword_table_en.jpg';

  return (
    <section id="rewards" className="u-mt--90">
      <h2 className="c-text--h2-ttl">{t('reward.001')}</h2>

      <p className="c-text--md u-mt--10">{t('reward.002')}</p>

      <ul className="c-list c-list--rewords u-mt--30">
        <li>
          <p>
            <img src="/images/icon_top_reward.png" alt="icon_top_reward" />
          </p>

          <div>
            <p className="title c-text--sub-ttl-md">{t('reward.003')}</p>
            <p className="description c-text--md">{t('reward.004')}</p>
          </div>
        </li>

        <li>
          <p>
            <img
              src="/images/icon_sharing_reward.png"
              alt="icon_sharing_reward"
            />
          </p>

          <div>
            <p className="title c-text--sub-ttl-md">{t('reward.005')}</p>
            <p className="description c-text--md">{t('reward.006')}</p>
          </div>
        </li>

        <li>
          <p>
            <img src="/images/icon_god_reward.png" alt="icon_god_reward" />
          </p>

          <div>
            <p className="title c-text--sub-ttl-md">{t('reward.007')}</p>
            <p className="description c-text--md">{t('reward.008')}</p>
          </div>
        </li>

        <p className="c-text--h2-ttl u-mt--90">{t('reward.009')}</p>

        <li className="c-text--md u-mt--20">
          <p>
            <img src="/images/icon_hero_reward.png" alt="icon_hero_reward" />
          </p>

          <div>
            <p className="title c-text--sub-ttl-md">{t('reward.010')}</p>
            <p className="decription c-text--md u-mt--10">{t('reward.014')}</p>
          </div>
        </li>

        <li className="c-text--md u-mt--20">
          <p></p>

          <div>
            <p className="title c-text--sub-ttl-md">{'PRIZE MECHANICS'}</p>
            <p className="decription c-text--md u-mt--10">
              {<Trans i18nKey={'reward.015'} />}
            </p>
          </div>
        </li>
      </ul>

      <p className="u-mt--30">
        <img src={imagePath} alt={`${imagePath}`} />
      </p>
    </section>
  );
};

export default Reword;
