import axios from 'axios';
import { devConsoleApi } from 'lib/common';
import { api_endpoint } from 'lib/constant';

export const fetchNftApi = async (
  tokenId: number,
  walletAddress: string,
  signature: string | null = null
): Promise<
  | {
      id: number;
      tokenId: number;
      walletAddress: string;
      isUsed: boolean;
      isLocked: boolean;
    }
  | {
      id: number;
      tokenId: number;
      walletAddress: string;
      isUsed: boolean;
      isLocked: boolean;
      UserEvent: [
        {
          event: {
            name: string;
          };
        },
      ];
    }
  | null
> => {
  devConsoleApi(`fetchNftApi()`, tokenId, walletAddress, signature);

  try {
    let response;

    if (signature) {
      response = await axios.post(
        `${api_endpoint}nft/detail`,
        {
          tokenId: tokenId,
          walletAddress: walletAddress,
          signature: signature,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } else {
      response = await axios.post(
        `${api_endpoint}nft/detail`,
        {
          tokenId: tokenId,
          walletAddress: walletAddress,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }
    devConsoleApi(`fetchNftApi() response`, response?.data?.nft);
    return response?.data?.nft;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('fetchNftApi error:', error.message);
    } else {
      console.error('fetchNftApi error:', error);
    }
    throw new Error('Failed to fetch nonce from server');
  }
};
