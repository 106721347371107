import { useTranslation, Trans } from 'react-i18next';

const Heros = () => {
  const { t } = useTranslation();

  return (
    <section id="heros" className="u-mt--90">
      <h2 className="c-text--h2-ttl">{t('heros.001')}</h2>

      <ul className="c-list c-list--heros u-mt--30">
        <li>
          <p>
            <img src="/images/nft/img_werewolf_01.jpg" alt="人狼" />
          </p>

          <div>
            <p className="title c-text--sub-ttl-lg">{t('hero.001')}</p>
            <p className="ruby c-text--md">{t('heroSub.001')}</p>
            <p className="status c-text--sub-ttl-md">
              {t('heros.002')}: 100
              <br />
              {t('heros.003')}: 10 / 500
            </p>
            <p className="description c-text--md">
              {t('heros.004')}
              <br />
              <br />
              {t('heros.005')}
              <br />
              {t('heros.006')}
            </p>
          </div>
        </li>

        <li>
          <p>
            <img src="/images/nft/img_priest.jpg" alt="聖職者" />
          </p>

          <div>
            <p className="title c-text--sub-ttl-lg">{t('hero.002')}</p>
            <p className="ruby c-text--md">{t('heroSub.002')}</p>
            <p className="status c-text--sub-ttl-md">
              {t('heros.002')}: 1 or 2 or 100
              <br />
              {t('heros.003')}: 244 / 500
            </p>
            <p className="description c-text--md">
              {t('heros.007')}
              <br />
              <br />
              {t('heros.008')}
            </p>
          </div>
        </li>

        <li>
          <p>
            <img src="/images/nft/img_devil_hunter.jpg" alt="デビルハンター" />
          </p>

          <div>
            <p className="title c-text--sub-ttl-lg">{t('hero.003')}</p>
            <p className="ruby c-text--md">{t('heroSub.003')}</p>
            <p className="status c-text--sub-ttl-md">
              {t('heros.002')}: 1 or 2 or 100
              <br />
              {t('heros.003')}: 244 / 500
            </p>
            <p className="description c-text--md">
              {t('heros.009')}
              <br />
              <br />
              {t('heros.010')}
            </p>
          </div>
        </li>

        <li>
          <p>
            <img src="/images/nft/img_child_wolf.jpg" alt="狼子供" />
          </p>

          <div>
            <p className="title c-text--sub-ttl-lg">{t('hero.004')}</p>
            <p className="ruby c-text--md">{t('heroSub.004')}</p>
            <p className="status c-text--sub-ttl-md">
              {t('heros.002')}: 0 or 120
              <br />
              {t('heros.003')}: 1 / 500
            </p>
            <p className="description c-text--md">
              {t('heros.011')}
              <br />
              <br />
              {t('heros.012')}
            </p>
          </div>
        </li>

        <li>
          <p>
            <img src="/images/nft/img_god_of_ai.jpg" alt="AI神" />
          </p>

          <div>
            <p className="title c-text--sub-ttl-lg">{t('hero.005')}</p>
            <p className="ruby c-text--md">{t('heroSub.005')}</p>
            <p className="status c-text--sub-ttl-md">
              {t('heros.013') + ': 100'}
              <br></br>
              {t('heros.003') + ': 1 / 500 '}
              <br></br>
              {/* <span className="u-color--red">{t('heros.014') + ': 450ETH'}</span> */}
            </p>
            <p className="description c-text--md">
              {t('heros.015')}
              <br />
              <br />
              {t('heros.016')}
              <br />
              {t('heros.017')}
            </p>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default Heros;
