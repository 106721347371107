import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dayMapping, totalSupply } from './../../../lib/constant';
import { selectLatestMonster } from './../../../lib/services';

const Battle = ({ monsters, renamedMonsters }) => {
  const { t } = useTranslation();
  const now = new Date();
  const [isLoading, setIsLoading] = useState(true);

  const formatMonsterData = (monster) => ({
    name: t('monster.00' + monster?.eid),
    main: monster.main,
    thumb: monster.thumb,
    startTime: new Date(monster.startTime),
    endTime: new Date(monster.endTime),
    date: new Date(monster.startTime).getDate(),
    endTime: new Date(monster.endTime),
    day: t(
      'day.' +
        dayMapping[
          new Date(monster.startTime).toLocaleString('en-US', {
            weekday: 'short',
          })
        ]
    ),
    maxHp: monster.maxHp,
    result: monster.result,
    vanguardCount: monster.vanguardCount,
    totalAttackCount: monster.totalAttackCount,
  });

  const schedules = monsters.map(formatMonsterData);

  const currentMonster =
    renamedMonsters[0] || selectLatestMonster(monsters) || {};
  const current = formatMonsterData(currentMonster);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [monsters, renamedMonsters]);

  if (isLoading || !current?.main) {
    return (
      <>
        <div className="p-battle-display loading__battle">
          <div className="p-battle-display__header">
            <p className="p-battle-display__header-title"></p>
          </div>

          <div className="p-battle-display__body">
            <p className="p-battle__body-img__loading">
              <div className="loading-spinner"></div>
            </p>
          </div>
          <div className="p-battle-display__footer">
            <div className="p-battle-display__history">
              <ul className="p-battle-display__history-list">
                <li className="p-battle-display__history-item doading__background"></li>
                <li className="p-battle-display__history-item doading__background"></li>
                <li className="p-battle-display__history-item doading__background"></li>
                <li className="p-battle-display__history-item doading__background"></li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="p-battle-display">
      <div className="p-battle-display__header">
        <p className="p-battle-display__header-title">
          {current.endTime < now ? (
            <span>{t(`battle.end`)}</span>
          ) : (
            <>
              <span>{t('battle.todays')}: </span>
              <span>{current.name}</span>
              <span> {'[' + t('battle.hp') + ' ' + current.maxHp + ']'}</span>
            </>
          )}
        </p>
      </div>

      <div className="p-battle-display__body">
        {!current.main ? (
          <p className="p-battle__body-img__loading">
            <div className="loading-spinner"></div>
          </p>
        ) : (
          <p className="p-battle__body-img">
            <img src={current.main} alt={`${current.main}`} />
          </p>
        )}
        <div className="p-battle-display__body-box">
          <p className="title">{t('battle.vanguard')}</p>
          <p className="counter">
            <span>
              {current.startTime < now ? current.vanguardCount : '--'}
            </span>
            <span> / {totalSupply} NFTs</span>
          </p>
        </div>
      </div>
      <div className="p-battle-display__footer">
        <div className="p-battle-display__history">
          <ul className="p-battle-display__history-list">
            {schedules.map((s, i) => (
              <li className="p-battle-display__history-item" key={i}>
                <p className="p-battle-display__history-item-img">
                  <img src={s.thumb} alt={`${s.thumb}`} />
                </p>

                <div className="p-battle-display__history-item-content">
                  <p className="title">
                    <span>{s.name}</span>
                    <span> {'[' + s.date + ' ' + s.day + ']'}</span>
                  </p>
                  <p className="counter">
                    <span>
                      {s.startTime < now ? s.totalAttackCount : '--'}{' '}
                    </span>
                    <span> / {totalSupply} NFTs</span>
                  </p>
                  <p className="result">
                    <span className={s.result === 'win' ? 'is-active' : ''}>
                      {t('battle.win')}
                    </span>
                    <span> / </span>
                    <span className={s.result === 'lose' ? 'is-active' : ''}>
                      {t('battle.lose')}
                    </span>
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Battle;
