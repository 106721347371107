import { createSlice } from '@reduxjs/toolkit';
import i18n from 'i18next';

export const langSlice = createSlice({
  name: 'lang',
  initialState: {
    lang: 'en',
  },
  reducers: {
    change: (state) => {
      state.lang = state.lang === 'en' ? 'ja' : 'en';
      i18n.changeLanguage(state.lang);
    },
  },
});

export const { change } = langSlice.actions;

export default langSlice.reducer;
