import axios from 'axios';
import { devConsoleApi } from 'lib/common';
import { api_endpoint } from 'lib/constant';

export interface MonsterApiResponse {
  id: number;
  eid: number;
  name: string;
  startTime: Date;
  endTime: Date;
  main: string;
  thumb: string;
  result: string;
  maxHp: number;
  vanguard: null | boolean;
  vanguardCount: number;
  totalAttackCount: number;
}

export async function monsterDataApi(): Promise<MonsterApiResponse[]> {
  devConsoleApi([`monsterDataApi()`]);

  try {
    const response = await axios.post<MonsterApiResponse[]>(
      `${api_endpoint}monster/data`
    );

    devConsoleApi(`monsterDataApi() response`, response?.data);
    return response?.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Failed to fetch monster data:', error.message);
    } else {
      console.error('An unexpected error occurred:', error);
    }
    return [];
  }
}
