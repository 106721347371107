import { x_url, discord_url, wlf_lp_url } from './../../lib/constant';

const Footer = () => {
  const items = [
    { href: 'mailto:info@wwwc.app', image: '/images/common/img_email.png' },
    {
      href: x_url,
      image: '/images/common/img_twitter.svg',
      isExternal: true,
    },
    {
      href: discord_url,
      image: '/images/common/img_discord.svg',
      isExternal: true,
    },
  ];

  return (
    <footer className="l-footer">
      <div className="l-footer__inner">
        <nav className="l-footer__nav">
          <ul className="l-footer__nav-list">
            {items.map((item, i) => (
              <li className="l-footer__nav-item" key={i}>
                <a
                  href={item.href}
                  className="l-footer__nav-item-link"
                  {...(item.isExternal
                    ? { target: '_blank', rel: 'noopener noreferrer' }
                    : {})}
                >
                  <img
                    src={item.image}
                    alt={`${item.image}`}
                    aria-hidden="true"
                  />
                </a>
              </li>
            ))}
          </ul>
        </nav>

        <div className="l-footer__title">
          <a href={wlf_lp_url} target="_blank" rel="noopener noreferrer">
            <img
              src="/images/common/icon_footer_logo.svg"
              alt="icon_footer_logo"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
