import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'stores/slices/langSlice';
import { Trans } from 'react-i18next';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useEthersSigner } from './../../lib/provider';
import { useAccount } from 'wagmi';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { x_url, discord_url } from './../../lib/constant';

const Header = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const lang = useSelector((state) => state.lang.lang);
  const items = [
    { href: '#monsters', text: <Trans i18nKey={'header.001'} /> },
    { href: '#rewards', text: t('header.002') },
    { href: '#heros', text: t('header.003') },
    { href: '#rules', text: t('header.004') },
    { href: '#strategy', text: t('header.005') },
    {
      href: x_url,
      text: t('header.006'),
      isExternal: true,
    },
    {
      href: discord_url,
      text: t('header.009'),
      isExternal: true,
    },
  ];
  const langs = [
    { value: 'en', text: t('header.007') },
    { value: 'ja', text: t('header.008') },
  ];

  const changeLang = () => {
    dispatch(change());
  };
  const { isConnected, address } = useAccount();

  const { openConnectModal } = useConnectModal();
  const signer = useEthersSigner();

  useEffect(() => {
    if (!isConnected) {
      openConnectModal();
    }
  }, [signer, isConnected, openConnectModal, address]);

  return (
    <header className="l-header">
      <div className="l-header__inner">
        <div className="l-header__block">
          <h1 className="l-header__title">
            <img
              src="/images/common/icon_header_logo.svg"
              alt="WLF LAST GENERATION"
            />
          </h1>

          <nav className="l-header__nav u-sp--hide">
            <ul className="l-header__nav-list">
              {items.map((item, i) => (
                <li className="l-header__nav-item" key={i}>
                  {item.isExternal ? (
                    <a
                      href={item.href}
                      className="l-header__nav-item-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.text}
                    </a>
                  ) : (
                    <a href={item.href} className="l-header__nav-item-link">
                      {item.text}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </nav>

          <div className="l-header__language">
            <ul
              className="l-header__language-list"
              onClick={() => changeLang()}
            >
              {langs.map((item, i) => (
                <li className="l-header__language-item" key={i}>
                  <a
                    href="/"
                    onClick={(e) => e.preventDefault()}
                    className={
                      'l-header__language-item-link ' +
                      (lang === item.value && 'is-active')
                    }
                  >
                    {item.text}
                  </a>
                </li>
              ))}
            </ul>
            {isConnected && (
              <div className="">
                <ConnectButton
                  className="m-4"
                  chainStatus="none"
                  showBalance={false}
                  accountStatus={'avatar'}
                />
              </div>
            )}
          </div>
        </div>

        <nav className="l-header__nav u-pc--hide">
          <ul className="l-header__nav-list">
            {items.map((item, i) => (
              <li className="l-header__nav-item" key={i}>
                <a href={item.href} className="l-header__nav-item-link">
                  {item.text}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
