import { header_images } from './../../../lib/constant';

const FirstView = () => {
  const now = new Date();

  let selectImage = header_images[0];

  if (now >= new Date('2024-05-16T16:00:00.000+09:00')) {
    // 日本標準時での16時
    selectImage = header_images[3];
  } else if (now >= new Date('2024-05-15T16:00:00.000+09:00')) {
    selectImage = header_images[2];
  } else if (now >= new Date('2024-05-14T16:00:00.000+09:00')) {
    selectImage = header_images[1];
  } else if (now >= new Date('2024-05-13T16:00:00.000+09:00')) {
    selectImage = header_images[0];
  }

  return (
    <div className="p-fv">
      <p className="p-fv__img">
        <img
          src={selectImage}
          alt="WLF LAST GENERATION"
          style={{
            height: '100%',
            minHeight: '100%',
          }}
        />
      </p>
    </div>
  );
};

export default FirstView;
