import { providers } from 'ethers';
import axios from 'axios';
import { initWolfLastGeneration } from './init';
import { JsonRpcProvider } from './provider';
import { consoleOnchain } from './common';
import { default_nft_info } from './constant';
import { fetchNftApi } from './api/nft';

export async function isHolder(
  signer: providers.JsonRpcSigner,
  address: string
): Promise<boolean> {
  consoleOnchain(`isHolder()`, signer, address);

  const provider = signer ? signer : JsonRpcProvider;
  const erc721 = await initWolfLastGeneration(provider);
  return (await erc721.balanceOf(address)).toNumber() > 0;
}

interface NftInfo {
  tokenId: number | null;
  isLocked: boolean;
  isUsed: boolean;
  image: string;
  name: string;
}

export async function getNftInfo(
  signer: providers.JsonRpcSigner,
  address: string
): Promise<NftInfo[]> {
  consoleOnchain(`getNftInfo()`, signer, address);

  if (!address) {
    return default_nft_info;
  }

  try {
    const provider = signer ? signer.provider : JsonRpcProvider;
    const nftInfo: NftInfo[] = [];
    const erc721 = await initWolfLastGeneration(provider);
    const balance = (await erc721.balanceOf(address))?.toNumber();

    if (balance > 0) {
      consoleOnchain(`balance: ${balance}, fetching images...`);

      for (let i = 0; i < balance; i++) {
        const tokenId = await erc721.tokenOfOwnerByIndex(address, i);
        const tokenIdNumber = tokenId.toNumber();
        if (tokenIdNumber >= 200 && tokenIdNumber <= 214) {
          continue;
        }
        const tokenURIPromise = erc721.tokenURI(tokenId);
        const nftPromise = fetchNftApi(tokenIdNumber, address);

        const [tokenURI, nft] = await Promise.all([
          tokenURIPromise,
          nftPromise,
        ]);

        const response = await axios.get(tokenURI);
        if (response.data.image) {
          nftInfo.push({
            tokenId: tokenIdNumber,
            isLocked: nft ? nft.isLocked : false,
            isUsed: nft ? nft.isUsed : false,
            image: response.data.image,
            name: response.data.name,
          });
        }
      }
      consoleOnchain(`getNftInfo() response`, nftInfo);
      return nftInfo;
    } else {
      consoleOnchain(`No tokens found, returning default image`);
      return default_nft_info;
    }
  } catch (error: any) {
    console.error(`getNftInfo()`, error);
    return default_nft_info;
  }
}
