import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

const Faq = () => {
  const { t } = useTranslation();

  return (
    <section className="u-mt--130">
      <h2 className="c-text--h2-ttl">{t('faq.001')}</h2>

      <dl className="u-mt--40 c-definition--faq">
        <div>
          <dt>Q. {t('faq.002')}</dt>
          <dd>{t('faq.003')}</dd>
        </div>

        <div>
          <dt>Q. {t('faq.004')}</dt>
          <dd>
            {t('faq.005')}
            <br />
            {t('faq.006')}
            <br />
            <br />
            {t('faq.007')}
          </dd>
        </div>

        <div>
          <dt>Q. {t('faq.008')}</dt>
          <dd>
            {t('faq.009')}
            <br />
            {t('faq.010')}
            <br />
            {t('faq.011')}
          </dd>
        </div>

        <div>
          <dt>Q. {t('faq.012')}</dt>
          <dd>
            {t('faq.013')}
            <br />
            <br />
            {t('faq.014')}
          </dd>
        </div>

        <div>
          <dt>Q. {t('faq.015')}</dt>
          <dd>
            {t('faq.016')}
            <br />
            {<Trans i18nKey={'faq.017'} />}
          </dd>
        </div>
      </dl>
    </section>
  );
};

export default Faq;
