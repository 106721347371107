import React from 'react';
import { useTranslation } from 'react-i18next';

const Schedule = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const imagePath =
    currentLanguage === 'ja'
      ? '/images/Schedule_Jp.jpg'
      : '/images/Schedule_En.jpg';

  return (
    <section id="schedule" className="u-mt--160">
      <h2 className="c-text--h2-ttl">{t('schedule.001')}</h2>
      <p className="u-mt--30">
        <img src={imagePath} alt={`${imagePath}`} />
      </p>
    </section>
  );
};

export default Schedule;
