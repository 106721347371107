import { mantle, mantleTestnet } from 'wagmi/chains';
import { devConsoleInfo } from './common';

export const env: string = process.env.REACT_APP_NODE_ENV || '';

export let api_endpoint: string = '';
export let erc721_address: string = '';
export let defult_rpc_url: string = '';
export let mantle_instance_explorer_url: string = '';

if (env === 'production') {
  api_endpoint = 'https://api.lastgen.wlfproject.com/api/';
  erc721_address = '0x78a4Ab4feAb364864de60e911723d063de2E128e';
  defult_rpc_url = 'https://mantle-mainnet.public.blastapi.io';
  mantle_instance_explorer_url =
    'https://explorer.mantle.xyz/token/0x78a4Ab4feAb364864de60e911723d063de2E128e/instance/';
} else if (env === 'development') {
  api_endpoint = 'https://stg-wlf-last-generation-be.vercel.app/api/';
  erc721_address = '0xa8Fe326B96A7b94A2150182fd02B4B7D92cF4145';
  defult_rpc_url = 'https://rpc.sepolia.mantle.xyz';
  mantle_instance_explorer_url =
    'https://explorer.sepolia.mantle.xyz/token/0xa8Fe326B96A7b94A2150182fd02B4B7D92cF4145/instance/';
} else {
  api_endpoint = 'http://localhost:8000/api/';
  erc721_address = '0xa8Fe326B96A7b94A2150182fd02B4B7D92cF4145';
  defult_rpc_url = 'https://rpc.sepolia.mantle.xyz';
  mantle_instance_explorer_url =
    'https://explorer.sepolia.mantle.xyz/token/0xa8Fe326B96A7b94A2150182fd02B4B7D92cF4145/instance/';
}

export const element_url = 'https://element.market/collections/wlastgeneration';
export const mintle_url =
  'https://mintle.app/explore/MANTLE:0x78a4ab4feab364864de60e911723d063de2e128e?sort=cheapest&inStockOnly=true';
export const lootex_url =
  'https://lootex.io/collections/mnt%3A0x78a4ab4feab364864de60e911723d063de2e128e';
export const x_url = 'https://twitter.com/WLF_Project';
export const wlf_lp_url = 'https://wlfproject.com/';
export const discord_url = 'https://discord.gg/wlf-project';
export const dayMapping = {
  Sun: '001',
  Mon: '002',
  Tue: '003',
  Wed: '004',
  Thu: '005',
  Fri: '006',
  Sat: '007',
};

export const mantle_info = {
  ...mantle,
  iconUrl:
    'https://pbs.twimg.com/profile_images/1597775748580134914/bLhE1aY1_400x400.jpg',
};

export const mantle_test_info = {
  ...mantleTestnet,
  id: 5003,
  name: 'Mantle Testnet',
  network: 'mantle',
  nativeCurrency: {
    decimals: 18,
    name: 'MNT',
    symbol: 'MNT',
  },
  rpcUrls: {
    default: { http: ['https://rpc.sepolia.mantle.xyz'] },
    public: { http: ['https://rpc.sepolia.mantle.xyz'] },
  },
  blockExplorers: {
    etherscan: {
      name: 'Mantle Testnet Explorer',
      url: 'https://explorer.testnet.mantle.xyz',
    },
    default: {
      name: 'Mantle Testnet Explorer',
      url: 'https://explorer.testnet.mantle.xyz',
    },
  },
  testnet: true,
};

export const currentDate = new Date();
export const totalSupply: number = 500;

export const default_nft_info = [
  {
    tokenId: null,
    isLocked: false,
    isUsed: false,
    image: 'https://nft-game-last-generation.wlfproject.com/images/lastgen.png',
    name: 'Last Generation NFT',
  },
];

export const header_images = [
  '/images/img_fv_dragon-todays.jpg',
  '/images/img_fv_succubus-todays.jpg',
  '/images/img_fv_devil-todays.jpg',
  '/images/img_fv_last_dragon-todays.jpg',
];

console.info(`env: ${env}`);

devConsoleInfo([
  `env: api_endpoint ${api_endpoint}, erc721_address ${erc721_address}, defult_rpc_url ${defult_rpc_url}, currentDate: ${currentDate.toUTCString()}, env ${env}`,
]);
