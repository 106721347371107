import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  connectorsForWallets,
  RainbowKitProvider,
  darkTheme,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import {
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
  coinbaseWallet,
  safeWallet,
  metaMaskWallet,
  trustWallet,
  uniswapWallet,
} from '@rainbow-me/rainbowkit/wallets';
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import Top from 'pages/Top';
import 'assets/css/main.css';
import { env, mantle_info, mantle_test_info } from './../lib/constant';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const projectId = 'a3b73d2039c3858bc39e1f97a15a5f08';

const App = () => {
  const defaultChains =
    env === 'production' ? [mantle_info] : [mantle_test_info];

  const { chains, publicClient } = configureChains(defaultChains, [
    publicProvider(),
  ]);

  const connectors = connectorsForWallets([
    {
      groupName: 'Recommended',
      wallets: [
        injectedWallet({ chains }),
        metaMaskWallet({ projectId, chains }),
        walletConnectWallet({ projectId, chains }),
        coinbaseWallet({ chains, appName: 'WLF Premint' }),
        safeWallet({
          chains: chains,
        }),
        trustWallet({ projectId, chains }),
        uniswapWallet({ projectId, chains }),
        rainbowWallet({ projectId, chains }),
      ],
    },
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  return (
    <div className="App">
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          chains={chains}
          modalSize="wide"
          showBalance={false}
          theme={darkTheme({
            accentColor: '#7b3fe4',
            accentColorForeground: 'white',
            borderRadius: 'medium',
            fontStack: 'rounded',
            overlayBlur: 'large',
          })}
        >
          <div className="l-wrapper">
            <Header />
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              style={{ width: 'auto' }}
            />
            <Top />
            <Footer />
          </div>
        </RainbowKitProvider>
      </WagmiConfig>
    </div>
  );
};

export default App;
