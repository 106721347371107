import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { getNftInfo } from './../../../lib/erc721';
import { default_nft_info } from './../../../lib/constant';
import { useEthersSigner } from './../../../lib/provider';
import NFTList from './../../Sidebar/NFTList';

const Sidebar = ({
  isConnected,
  monsters,
  address,
  signature,
  setMonsters,
  setRenamedMonsters,
  fetchNonceAndLogin,
}) => {
  const { t } = useTranslation();
  const signer = useEthersSigner();
  const [nftInfos, setNftInfos] = useState(default_nft_info);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchNftImages = async () => {
      try {
        const infos = await getNftInfo(signer, address);
        setIsLoading(false);
        if (infos.length > 0) {
          setNftInfos(infos);
        } else {
          setTimeout(fetchNftImages, 300);
        }
      } catch (error) {
        console.error('Failed to fetch NFT image:', error);
      }
    };

    if (address) {
      fetchNftImages();
    } else {
      setIsLoading(false);
    }
  }, [signer, address]);

  return (
    <div className="l-content__side">
      <h3 className="c-text--h3-ttl">{t('monsters.018')}</h3>
      <aside className="l-sidebar">
        <NFTList
          nftInfos={nftInfos}
          isConnected={isConnected}
          monsters={monsters}
          address={address}
          signature={signature}
          isLoading={isLoading}
          setMonsters={setMonsters}
          setRenamedMonsters={setRenamedMonsters}
          fetchNonceAndLogin={fetchNonceAndLogin}
        />
      </aside>
    </div>
  );
};

export default Sidebar;
